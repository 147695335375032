.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.title {
  font-size: 1.4rem;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}

@media only screen and (max-width: 600px) {
  .root {
    flex-direction: column;
  }

  .section {
    width: 100%;
  }

  .buttons {
    flex-wrap: nowrap;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
