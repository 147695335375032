@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

html {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  position: relative;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

a {
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #1d8954;
  text-decoration: underline;
}
