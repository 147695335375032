@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
html {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

body {
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

a {
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #1d8954;
  text-decoration: underline;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
}

.Top_root__15S1D {
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Top_header__1Q9ZT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100vw;
  height: 8vh;
  background-color: black;
}

.Top_headerText__3LvN7 {
  color: white;
  font-weight: bold;
  margin: 0 5%;
}

.Top_headerTextLink__3_kcB:hover {
  color: #1d8954;
  cursor: pointer;
  text-decoration: underline;
}

.Top_result__3RMNF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 90vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .Top_result__3RMNF {
    height: 70vh;
    overflow-y: scroll;
  }
}

.Title_root__1bzV0 {
  font-size: 84px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.52px;
  line-height: 84px;
  text-transform: none;
  margin: 10px;
}

.Button_root__2bb-J {
  padding: 15px;
  width: 10em;
  border-radius: 1.6rem;
  margin: 10px;
  border: 3px solid black;
  outline: none;
  background-color: white;
}

.Button_root__2bb-J:hover {
  cursor: pointer;
  background-color: #1d8954;
  border: 3px solid #1d8954;
  color: white;
  font-weight: bold;
}

.Button_active__TCjlx {
  background-color: #1d8954;
  border: 3px solid #1d8954;
  color: white;
  font-weight: bold;
}

.Buttons_root__3voqL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0;
}

.Buttons_title__w9a-F {
  font-size: 1.4rem;
}

.Buttons_section__YJXKC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 33%;
}

@media only screen and (max-width: 600px) {
  .Buttons_root__3voqL {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Buttons_section__YJXKC {
    width: 100%;
  }

  .Buttons_buttons__3sRJW {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
}

.Buttons_buttons__3sRJW {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Track_root__3BYQc {
  width: 500px;
  background-color: white;
  border: 3px solid black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  min-height: 220px;
  margin: 10px 10px;
  border-radius: 2rem;
}

.Track_rank__2ID48 {
  font-size: 2.5rem;
}

.Track_artwork__27jT8 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  height: 160px;
  width: 160px;
  margin-left: 20px;
}

.Track_info__2eWzx {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 15px 0;
}

.Track_trackName__IU8DP {
  font-weight: bold;
  text-decoration: underline;
  margin: 0 10px;
}

.Track_artistName__A44wN {
  margin: 10px 0;
}

.Track_albumName__k8cCq {
  margin: 0 10px;
}

@media only screen and (max-width: 500px) {
  .Track_root__3BYQc {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 80vw;
    height: auto;
  }

  .Track_artwork__27jT8 {
    margin: 20px 0 0 0;
  }

  .Track_info__2eWzx {
    max-width: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin: 10px 0;
    margin-bottom: 20px;
  }
}

.Artist_root__33FvZ {
  width: 500px;
  background-color: white;
  border: 3px solid black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  min-height: 220px;
  margin: 10px 10px;
  border-radius: 2rem;
}

.Artist_rank__3pVXA {
  font-size: 2.5rem;
}

.Artist_name__KXMLc {
  color: black;
}

.Artist_name__KXMLc:hover {
  color: #1d8954;
}

.Artist_artwork__LEhT- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  height: 160px;
  width: 160px;
  margin-left: 20px;
}

.Artist_info__3V8iC {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 15px 0;
  font-size: 1.5rem;
}

.Artist_genre__1sLrT {
  font-size: 0.9rem;
  padding: 0 10px;
}

@media only screen and (max-width: 500px) {
  .Artist_root__33FvZ {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 80vw;
    height: auto;
  }

  .Artist_artwork__LEhT- {
    margin: 20px 0 0 0;
  }

  .Artist_info__3V8iC {
    max-width: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin: 10px 0;
    margin-bottom: 20px;
  }
}

