.root {
  width: 500px;
  background-color: white;
  border: 3px solid black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 220px;
  margin: 10px 10px;
  border-radius: 2rem;
}

.rank {
  font-size: 2.5rem;
}

.name {
  color: black;
}

.name:hover {
  color: #1d8954;
}

.artwork {
  display: flex;
  align-self: center;
  height: 160px;
  width: 160px;
  margin-left: 20px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  font-size: 1.5rem;
}

.genre {
  font-size: 0.9rem;
  padding: 0 10px;
}

@media only screen and (max-width: 500px) {
  .root {
    flex-direction: column;
    width: 80vw;
    height: auto;
  }

  .artwork {
    margin: 20px 0 0 0;
  }

  .info {
    max-width: 100%;
    align-items: center;
    margin: 10px 0;
    margin-bottom: 20px;
  }
}
