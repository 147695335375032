.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 8vh;
  background-color: black;
}

.headerText {
  color: white;
  font-weight: bold;
  margin: 0 5%;
}

.headerTextLink:hover {
  color: #1d8954;
  cursor: pointer;
  text-decoration: underline;
}

.result {
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .result {
    height: 70vh;
    overflow-y: scroll;
  }
}
