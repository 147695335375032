.root {
  padding: 15px;
  width: 10em;
  border-radius: 1.6rem;
  margin: 10px;
  border: 3px solid black;
  outline: none;
  background-color: white;
}

.root:hover {
  cursor: pointer;
  background-color: #1d8954;
  border: 3px solid #1d8954;
  color: white;
  font-weight: bold;
}

.active {
  background-color: #1d8954;
  border: 3px solid #1d8954;
  color: white;
  font-weight: bold;
}
